@import 'src/components/shared/search-bar/SearchBar.module';
@import 'src/styles/mixins';
$searchIconSize: 4rem;

.searchForm {
  display: flex;
  padding-bottom: 5 * $baseGap;
}

.searchButton {
  min-width: $searchIconSize;
}

.searchIcon {
  @include search-icon($searchIconSize);
}

.corporateName {
  flex-grow: 2;
  margin-right: $gapM;
}

.registrationDate {
  flex-grow: 1;
  margin-right: $gapM;
}
