@import 'src/styles/mixins';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.minMaxField{
  display: flex;
  flex-direction: row;
}
