@import 'src/styles/mixins';

.previewContentContainer {
  min-width: $bodyMinWidth;
  height: 100%;
}

.dialogContent {
  height: 80vh;
}

.pdfContent {
  width: 100%;
  height: 100%;
  border: 1px solid $lineColor;
}

.viewPdf {
  border: 0;
  margin-left: $gapM;
}